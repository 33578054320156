// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function convertDateTimeIntoDate(data: any) {
  const date = new Date(data);

  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

export function getEHRTokenFromLocalStorage(): string {
  const localStorageData = localStorage.getItem('ehr_token');
  if (localStorageData !== null) {
    const data = JSON.parse(localStorageData);
    if (data && data.data && data.data.access_token) {
      return data.data.access_token;
    }
  }
  return '';
}

export function formatTime(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const pad = (num: number) => (num < 10 ? `0${num}` : `${num}`);

  return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
}
