/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Box, Card, Title, createStyles, Center, Menu, UnstyledButton, Group, rem, Text } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import Breadcrumb from '../components/Breadcrumb';
import { ehrList, getOrganizationsByEhr } from '../fhirApi';
import { useMedplum } from '@medplum/react';

const useStyles = createStyles((theme, { opened }: { opened: boolean }) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    height: '100%', 
  },

  title: {
    lineHeight: '16px',
    textAlign: 'center',
  },

  control: {
    width: 'max-content',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `6px ${theme.spacing.xs}`,
    marginRight: `${theme.spacing.lg}`,
    borderRadius: theme.radius.sm,
    border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2]}`,
    transition: 'background-color 150ms ease',
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[opened ? 5 : 6] : opened ? theme.colors.gray[0] : theme.white,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
    },
  },

  label: {
    fontWeight: 400,
    fontSize: theme.fontSizes.sm,
    marginRight: theme.spacing.sm,
  },

  activeLabel: {
    fontWeight: 700,
    fontSize: theme.fontSizes.md,
  },

  activeBullet: {
    width: '20px',
    height: '20px',
  },

  filterChip: {
    '& label': {
      backgroundColor: '#E9F3FF',
      color: '#5D6874',
      '&[data-checked]': {
        backgroundColor: '#C7E0FF !important',
        color: '#444444 !important',
        fontWeight: 700,
      },
      '& span': {
        display: 'none',
      },
    },
  },
}));

interface TitleProps {
  title: string;
  EhrFilter?: boolean;
  organizationFilter?: boolean;
  selectedLabel?: string;
  selectedEhrType?: any;
  onEhrTypeSelect?: (selectedItem: any) => void;
  onOrganizationSelect?: (selectedItem: any) => void;
  getOrganizationSelect?: (selectedItem: any) => void;
}

const TitleComponent: React.FC<TitleProps> = ({
  title,
  EhrFilter,
  organizationFilter,
  selectedLabel,
  selectedEhrType,
  onEhrTypeSelect,
  onOrganizationSelect,
  getOrganizationSelect
}) => {
  const [opened, setOpened] = useState(false);
  const [selected, setSelected] = React.useState({ id: 'all', label: 'All' });
  const { classes } = useStyles({ opened });
  const [ehrTypes, setEhrTypes] = useState([]);
  const [organizationsByEHR, setOrganizationsByEHR] = useState([{ id: 'all', label: 'All' }]);
  const [organization, setOrganization] = React.useState<any | null>();
  const medplum = useMedplum();
  useEffect(() => {
    if(EhrFilter) {
      ehrList(medplum)
      .then((response) =>{
        const updatedResponse = response?.data.map((item: any) => ({
          ...item,
        }));
        setEhrTypes(updatedResponse);
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
      });
    }
  }, [EhrFilter])
  const items = [
    <Menu.Item onClick={() => handleEhrTypeSelect({ id: 'all', label: 'All' })} key="all">
      All
    </Menu.Item>,
    ...ehrTypes?.map((item: any) => (
      <Menu.Item onClick={() => handleEhrTypeSelect(item)} key={item.id}>
        {item.label}
      </Menu.Item>
    )),
  ];

  const organizations = [
    <Menu.Item onClick={() => handleOrganizationSelect({ id: 'all', name: 'All' })} key="all">
      All
    </Menu.Item>,
    ...organizationsByEHR?.map((item:any) => (
      <Menu.Item key={item.id} onClick={() => handleOrganizationSelect(item)}>
        {item.name}
      </Menu.Item>
    )),
  ];

  const handleEhrTypeSelect = (item: any) => {
    if (onEhrTypeSelect) {
      setSelected(item);
      onEhrTypeSelect(item);
    }
  };

  const handleOrganizationSelect = (item: any) => {
    if (onOrganizationSelect) {
      setOrganization(item);
      onOrganizationSelect(item);
    }
  };

  useEffect(() => {
    organizationsByEHR.filter((item: any) => {
      if (selected.label === 'All') {
        setOrganization({ id: 'all', name: 'All' });
      }
      else if (item.id === organization?.id) {
        setOrganization(item);
      }
    });
  }, [selected, organizationsByEHR]);

  useEffect(() => {
    if(organizationFilter) {
      getOrganizationsByEhr(medplum, selected.label)
      .then((response) =>{
        setOrganizationsByEHR(response?.data);
        setOrganization({ id: 'all', name: 'All' });
        if(getOrganizationSelect) {
          getOrganizationSelect({ id: 'all', name: 'All' });
        }
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
      });
    }
  }, [organizationFilter, selected.label])

  const isTitleLong = title.length > 12;
  return (
    <>
      <Card
        mx="lg"
        my="sm"
        shadow="xs"
        radius="sm"
        p="sm"
        display= "flex"
        sx={{ justifyContent: 'space-between', alignItems: 'baseline', flexDirection: 'row' }}
        className={`${isTitleLong ? 'titleResponsive' : ''} bg-main-light`}
      >
        <Center>{<Breadcrumb />}</Center>
        <Center>
          <Text className={classes.title} variant="sm" size="16px" fw="bold">
            {title}
          </Text>
        </Center>
      </Card>

      {(EhrFilter || organizationFilter) && (
        <Box mx="lg" mb="md" mt="lg" px="sm" sx={{ display: 'flex', alignItems: 'baseline', flexDirection: 'row' }}>
          <Center>
            <Text variant="sm" size="16px" fw="bold">
              {'Filters : '}
            </Text>
            {EhrFilter && (
              <Menu
                onOpen={() => setOpened(true)}
                onClose={() => setOpened(false)}
                radius="md"
                width="200px"
                withinPortal
              >
                <Center style={{ justifyContent: 'flex-start' }}>
                  <Title variant="h6" size={14} ml="lg" mr="xs">
                    EHR Type :
                  </Title>
                  <Menu.Target>
                    <UnstyledButton className={classes.control}>
                      <Group spacing="xs">
                        <span className={classes.label}>{selectedLabel}</span>
                      </Group>
                      <IconChevronDown size="1rem" />
                    </UnstyledButton>
                  </Menu.Target>
                  <Menu.Dropdown>{items}</Menu.Dropdown>
                </Center>

                {EhrFilter && organizationFilter && (
                  <Text variant="sm" size="16px" fw="bold">
                    {'|'}
                  </Text>
                )}
              </Menu>
            )}

            {organizationFilter && (
              <Menu
                onOpen={() => setOpened(true)}
                onClose={() => setOpened(false)}
                radius="md"
                width="target"
                withinPortal
              >
                <Center style={{ justifyContent: 'flex-start' }}>
                  <Title variant="h6" size={14} ml="lg" mr="xs">
                    Organizations :
                  </Title>
                  <Menu.Target>
                    <UnstyledButton className={classes.control}>
                      <Group spacing="xs">
                        <span className={classes.label}>{organization?.name}</span>
                      </Group>
                      <IconChevronDown size="1rem" />
                    </UnstyledButton>
                  </Menu.Target>
                  <Menu.Dropdown sx={{ width: '11rem!important' }}>{organizations}</Menu.Dropdown>
                </Center>
              </Menu>
            )}
          </Center>
        </Box>
      )}
    </>
  );
};

export default TitleComponent;
