/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable array-callback-return */
/* eslint-disable no-debugger */
import { Box, Card, Grid, createStyles, Text, Title, Center, Tabs, Accordion } from '@mantine/core';
import { useEffect, useState } from 'react';
// import Header from '../TitleComponent';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { PatientDetails } from './PatientDetails';
import { fetchPatientDetails, analyticsSummary, heartRateAnalytics, weightAnalytics, bloodPressureAnalytic } from '../../fhirApi';
import { ResourceAvatar, useMedplum } from '@medplum/react';
import { formatAddress } from '@medplum/core';
import { Link, useParams } from 'react-router-dom';
import TitleComponent from '../../components/TitleComponent';
import { AIInsights } from './AIInsights';

const colors = ['#8DBEF3', '#4D8CD0', '#8DBEF3', '#1B5390', '#3673B6', '#8DBEF3', '#4D8CD0', '#8DBEF3'];

export interface PatientProps {
  id: string;
  name: any;
  address: any;
  telecom: any;
  gender: string;
  birthDate: string;
  identifier: any;
}

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    height: '100%',
  },

  section: {
    padding: theme.spacing.md,
  },

  footer: {
    padding: theme.spacing.md,
    borderTop: `1px solid #EEF1F6 !important`,
  },

  header: {
    padding: theme.spacing.md,
    borderBottom: `1px solid #EEF1F6 !important`,
  },

  icon: {
    padding: theme.spacing.xs,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#EDF5FF',
    height: '40px',
    width: '40px',
    borderRadius: '25px',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
  },

  scrollbar: {
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: '#ccc #f3f3f3',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#ccc',
        borderRadius: '8px',
        '&:hover': {
          background: '#aaa',
        },
      },
    },
  },

  label: {
    fontWeight: 400,
    fontSize: theme.fontSizes.sm,
    marginRight: theme.spacing.sm,
  },

  table: {
    'tr:first-of-type td': {
      color: 'blue !important',
    },
  },

  tableRow: {
    padding: '30px',
  },

  highlitedRow: {
    padding: '30px',
    color: 'blue !important',
  },

  dBlock: {
    [theme.fn.smallerThan('sm')]: {
      display: 'block',
    },
  },

  customTabs: {
    '& button': {
      fontSize: '14px',
      color: '#000',
      background: '#eee',
      marginRight: '10px',
    },
    '& button[data-active]': {
      color: '#e9f3ff  ',
      background: '#1b5390 !important',
      backgroundColor: '#1b5390 !important',
    },
  },

  customTabPanel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '300px',
  },
}));

export function Patient(): JSX.Element {
  const { classes } = useStyles();
  const medplum = useMedplum();
  const patientID = useParams().id!;
  const [patientCalories, setPatientCalories] = useState([]);
  const [checkAIinsights, setCheckAIinsights] = useState<boolean>(false);
  const [patientData, setPatientData] = useState<PatientProps>({
    id: '',
    name: '',
    address: {},
    telecom: [],
    gender: '',
    birthDate: '',
    identifier: {},
  });
  const tabs = [
    { value: 'activity', name: 'Activity Summary' },
    { value: 'heartRate', name: 'Heart Rate' },
    { value: 'bloodPressure', name: 'Blood Pressure' },
    { value: 'weight', name: 'Weight' },
    // { value: 'temperature', name: 'Temperature' },
    // { value: 'spo2', name: 'SPO2' },
    // { value: 'painLevel', name: 'Pain Level' },
    // { value: 'glucose', name: 'Blood Glucose Level' },
    // { value: 'bmi', name: 'BMI' },
  ];

  useEffect(() => {
    const graphqlQuery = `${
      '{ Patient(id: "' +
      patientID +
      '") { resourceType id name { given family } address { line city state country } telecom {system value} gender birthDate identifier { value } } }'
    }`;

    fetchPatientDetails(medplum, graphqlQuery)
      .then((response: any) => {
        setPatientData(response?.data.Patient as PatientProps);
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
      });
    //calling summary API
    getAnalyticsSummary();
  }, []);

  const PatientBarChart = ({ data, dataKey, xAxisLabel, yAxisLabel }: any) => {
    return (
      <ResponsiveContainer width="100%" height={150} aspect={6}>
        <BarChart
          width={500}
          height={150}
          data={data}
          barSize={30}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <XAxis dataKey="name" axisLine={false} tickLine={false}>
            <Label value={xAxisLabel} offset={0} position="bottom" fill="#000" fontWeight="700" />
          </XAxis>
          <YAxis tickLine={false} axisLine={false}>
            <Label value={yAxisLabel} offset={0} position="insideBottomLeft" fill="#000" fontWeight="700" angle={-90} />
          </YAxis>
          <CartesianGrid strokeDasharray="1 1" />
          <Tooltip />
          <Bar dataKey={dataKey} name={activeTab === 'activity' ? 'Step Count' : activeTab === 'heartRate' ? 'Heart Rate' : 'Blood Pressure' } />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  function getName(name: any): string {
    let firstName: any = [];
    name?.given.forEach((entry: any) => {
      firstName.push(entry);
    });
    let formmatedFirstName = firstName.join(' ');
    let patientName = formmatedFirstName ? formmatedFirstName + ' ' + name?.family : name?.family;
    return patientName;
  }

  const [activeTab, setActiveTab] = useState(tabs[0].value);
  const [weightAnalyticsData , setWeightAnalyticsData] = useState([]);
  const [bloodPressureData, setBloodPressureData] = useState([]);
  const [heartRateAnalyticsData, setHeartRateAnalyticsData] = useState([]);

  const handleTabChange = (tab: any) => {
    setActiveTab(tab);
    if (tab === 'activity') {
      getAnalyticsSummary();
    } else if (tab === 'heartRate') {
      getHeartRateAnalytics();
    } else if (tab === 'bloodPressure') {
      getBloodPressureAnalytic();
    } else if (tab === 'weight') {
      getWeightAnalytics();
    }
  };

  const getAnalyticsSummary = () => {
    analyticsSummary(medplum, patientID).then((response) => {
      const updatedResponse = response?.data.map((item: any) => ({
        ...item,
        name: convertDateIntoDay(item.date),
      }));
      setPatientCalories(updatedResponse)
    }).catch((error) => {
      console.log(error)
    })
  }

  const getHeartRateAnalytics = () => {
    heartRateAnalytics(medplum, patientID).then((response) => {
      const updatedResponse = response?.data.map((item: any) => ({
        ...item,
        name: convertDateIntoDay(item.date),
      }));
      setHeartRateAnalyticsData(updatedResponse)
    }).catch((error) => {
      console.log(error)
    })
  }

  const getWeightAnalytics = () => {
    weightAnalytics(medplum, patientID).then((response) => {
      const updatedResponse = response?.data.map((item: any) => ({
        ...item,
        name: convertDateIntoDay(item.date),
      }));
      setWeightAnalyticsData(updatedResponse)
    }).catch((error) => {
      console.log(error)
    })
  }

  const getBloodPressureAnalytic = () => {
    bloodPressureAnalytic(medplum, patientID).then((response) => {
      const updatedResponse = response?.data.map((item: any) => ({
        ...item,
        name: convertDateIntoDay(item.date),
      }));
      setBloodPressureData(updatedResponse)
    }).catch((error) => {
      console.log(error)
    })
  }

  const convertDateIntoDay = (date : Date) => {
    const dateObject = new Date(date);
    return dateObject.toLocaleDateString('en-US', { weekday: 'long' }).slice(0, 3);
  }

  const coloredData = patientCalories.map((entry: any, index) => ({
    ...entry,
    fill: colors[index % colors.length],
  }));

  return (
    <>
      <Box px="lg" pt="sm">
        <TitleComponent title={''} />
      </Box>

      <Box px="lg" pb="sm">
        <Card withBorder radius="md" className={classes.card}>
          <Card.Section className={classes.header} p="lg">
            <Center className={classes.dBlock} style={{ justifyContent: 'space-between' }}>
              <Text fw={700}>Patient Information</Text>
              <Text fw={700}>
                Medical Record No: {patientData.identifier ? patientData?.identifier[0]?.value : 'NA'}{' '}
              </Text>
            </Center>
          </Card.Section>

          <Card.Section className={classes.section} p="lg" style={{ borderBottom: '0px !important' }}>
            <Grid columns={12}>
              <Grid.Col span={12} sm={6} lg={1}>
                <Center>
                  <ResourceAvatar value={patientData} size="xl" radius="50px" />
                </Center>
              </Grid.Col>
              <Grid.Col span={12} sm={6} lg={4}>
                <Grid>
                  <Grid.Col span={12} sm={6} lg={12}>
                    <Title variant="h1" className="f-dark" fw={700}>
                      {getName(patientData?.name[0])}
                    </Title>
                  </Grid.Col>
                </Grid>
                <Grid>
                  <Grid.Col span={6} sm={6} lg={4}>
                    <Text className="f-dark" fw={600}>
                      Date of Birth
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={6} sm={6} lg={8}>
                    <Text className="f-dark">{patientData?.birthDate || 'NA'}</Text>
                  </Grid.Col>
                </Grid>
                <Grid>
                  <Grid.Col span={6} sm={6} lg={4}>
                    <Text className="f-dark" fw={600}>
                      Gender
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={6} sm={6} lg={8}>
                    <Text className="f-dark" fw={500}>
                      {patientData?.gender || 'NA'}
                    </Text>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              <Grid.Col span={12} sm={6} lg={5}>
                <Grid>
                  <Grid.Col span={6} sm={6} lg={4}>
                    <Text className="f-dark" fw={600}>
                      Address
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={6} sm={6} lg={8}>
                    <Text className="f-dark" fw={500}>
                      {patientData?.address ? formatAddress(patientData?.address?.[0] || {}) : 'NA'}
                    </Text>
                  </Grid.Col>
                </Grid>
                <Grid>
                  <Grid.Col span={6} sm={6} lg={4}>
                    <Text className="f-dark" fw={600}>
                      Mobile
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={6} sm={6} lg={8}>
                    <Text className="f-dark" fw={500}>
                      {patientData?.telecom?.find((t: any) => t.system === 'phone')?.value || 'NA'}
                    </Text>
                  </Grid.Col>
                </Grid>
                <Grid>
                  <Grid.Col span={6} sm={6} lg={4}>
                    <Text className="f-dark" fw={600}>
                      Email Address
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={6} sm={6} lg={8}>
                    <Text className="f-dark" fw={500}>
                      {patientData?.telecom?.find((t: any) => t.system === 'email')?.value || 'NA'}
                    </Text>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              <Grid.Col
                span={12}
                sm={6}
                lg={2}
                style={{ justifyContent: 'center', display: 'flex', alignItems: 'flex-end' }}
              >
                <Center style={{ justifyContent: 'center' }}>
                  <Link
                    style={{
                      textDecoration: 'none',
                      color: '#fff',
                      backgroundColor: '#3673B6',
                      padding: '3px 5px',
                      fontSize: '11px',
                      fontWeight: 700,
                      borderRadius: '2px',
                    }}
                    target="_blank"
                    to={`${'/Patient/' + patientData.id + '/details'}`}
                  >
                    View
                  </Link>
                </Center>
              </Grid.Col>
            </Grid>
          </Card.Section>
        </Card>
      </Box>

      <Box px="lg" py="sm">
        <Accordion className='ai-insights' onClick={() => setCheckAIinsights(true)}>
          <Accordion.Item value="ai-insights">
            <Accordion.Control>AI Insights</Accordion.Control>
            <Accordion.Panel>
              {checkAIinsights && (
                <AIInsights/>
              )}
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Box>


      <Box px="lg" py="sm">
      <Accordion className='ai-insights'>
          <Accordion.Item value="vital-sign">
            <Accordion.Control>Vital Signs</Accordion.Control>
            <Accordion.Panel>
              <Card className={classes.card}>
                <Card.Section className={classes.section} p="lg" style={{ borderBottom: '0px !important' }}>
                  <Tabs
                    variant="pills"
                    radius="lg"
                    defaultValue={activeTab}
                    className={classes.customTabs}
                    onTabChange={(newTab) => handleTabChange(newTab)}
                  >
                    <Tabs.List style={{ whiteSpace: 'nowrap', flexWrap: 'nowrap', overflowX: 'auto'}}>
                      {tabs.map((t) => (
                        <Tabs.Tab key={t.value} value={t.value}>
                          {t.name}
                        </Tabs.Tab>
                      ))}
                    </Tabs.List>

                    {tabs.map((t) => (
                      <Tabs.Panel key={t.value} value={t.value} pt="md" className={classes.customTabPanel}>
                        {t.value === 'weight' ? (
                          <ResponsiveContainer width="100%" height={300}>
                            <AreaChart
                              width={500}
                              height={300}
                              data={weightAnalyticsData}
                              margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 0,
                              }}
                            >
                              <defs>
                                <linearGradient id="areaGradient" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#2859C5" stopOpacity={0.5} />
                                  <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0} />
                                </linearGradient>
                              </defs>

                              <XAxis dataKey="name" tickLine={false} />
                              <YAxis tickLine={false} axisLine={false} />
                              <Tooltip />

                              <Area
                                type="monotone"
                                dataKey="data"
                                name="Weight"
                                stroke="#2859C5"
                                strokeWidth={2}
                                fill="url(#areaGradient)"
                              />
                            </AreaChart>
                          </ResponsiveContainer>
                        ) : t.value === 'bloodPressure' ? (
                          <ResponsiveContainer width="100%" height={150} aspect={6}>
                            <LineChart
                              width={500}
                              height={300}
                              data={bloodPressureData}
                              margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name" />
                              <YAxis />
                              <Tooltip />
                              <Legend />
                              <Line type="monotone" dataKey="systolic" stroke="#8884d8" activeDot={{ r: 8 }} />
                              <Line type="monotone" dataKey="diastolic" stroke="#82ca9d" />
                            </LineChart>
                          </ResponsiveContainer>
                        ) : t.value === 'heartRate' ? (
                          <ResponsiveContainer width="100%" height={150} aspect={6}>
                            <LineChart
                              width={500}
                              height={300}
                              data={heartRateAnalyticsData}
                              margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name" />
                              <YAxis />
                              <Tooltip />
                              <Legend />
                              <Line type="monotone" dataKey="data" name="Heart Rate (bpm)" stroke="#8884d8" activeDot={{ r: 8 }} />
                            </LineChart>
                          </ResponsiveContainer>
                          ): (
                          <PatientBarChart
                            data={coloredData}
                            dataKey="data"
                            xAxisLabel="Days"
                            yAxisLabel="Step Counts"
                          />
                        )}
                      </Tabs.Panel>
                    ))}
                  </Tabs>
                </Card.Section>
              </Card>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Box>

      <Box px="lg" py="sm">
       <PatientDetails />
      </Box>
    </>
  );
}
