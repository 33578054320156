import { Box, Grid } from '@mantine/core';

function TermsAndConditions() {
  return (
    <Box>
      <Grid
        style={{
          border: '1px solid #ccc',
          margin: '24px',
          borderRadius: '8px',
        }}
      >
        <Grid.Col span={12} md={12} style={{ paddingLeft: '5%' }}>
          <h1 style={{ textAlign: 'center' }}>Terms and Conditions</h1>
          <p>
            BY ACCESSING OR USING THIS WEBSITE OR MOBILE APP, YOU ARE DEEMED TO HAVE READ AND AGREED TO THE FOLLOWING
            TERMS AND CONDITIONS:
          </p>
          <p>
            THESE TERMS AND CONDITIONS ARE SUBJECT TO THE UNIFORM ARBITRATION ACT, S.C. CODE ANN. §15-48- 10 ET SEQ.{' '}
          </p>
          <p>
            Any controversy or claim arising out of or relating to these terms and conditions, or the breach thereof,
            shall be settled in Charleston, South Carolina by arbitration administered by the American Arbitration
            Association under its Commercial Arbitration Rules, and judgment on the award rendered by the arbitrator(s)
            may be entered in any court having jurisdiction thereof.
          </p>
          <div>
            <h2>Definitions:</h2>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', marginLeft: '20px' }}>
                <p style={{ marginRight: '10px' }}>A.</p>
                <p style={{ flex: '1' }}>
                  "Client,” “You,” “Your,” and “User” shall refer to the person accessing and/or using this website,
                  regardless of whether this person is an individual, business entity, charity, or religious institution
                  registered with the Secretary of the State of South Carolina or any other State or any Country. As
                  Client and/or User, you hereby accept My Wellness ID LLC Terms and Conditions in their entirety.
                </p>
              </div>
              <div style={{ display: 'flex', marginLeft: '20px' }}>
                <p style={{ marginRight: '10px' }}>B.</p>
                <p style={{ flex: '1' }}>
                  "My Wellness ID LLC,” “My Wellness ID LLC,” “Company,” “Ourselves,” “We,” and “Us” shall refer to My
                  Wellness ID LLC, a Limited Liability Company governed by the laws of the State of South Carolina, with
                  its principal office located at 334 East Bay Street Unit 233 Charleston, South Carolina, 29401, its
                  representatives, and affiliates.
                </p>
              </div>
              <div style={{ display: 'flex', marginLeft: '20px' }}>
                <p style={{ marginRight: '10px' }}>C.</p>
                <p style={{ flex: '1' }}>
                  "Party,” “Parties,” or “Us” shall refer to any and all users of My Wellness ID LLC and
                  MYWELLNESSID.COM, in an individual, collective, and corporate capacity.
                </p>
              </div>
              <div style={{ display: 'flex', marginLeft: '20px' }}>
                <p style={{ marginRight: '10px' }}>D.</p>
                <p style={{ flex: '1' }}>
                  "Terms,” “Conditions,” or “Terms and Conditions” shall refer to the following document in its
                  entirety, and shall not include any extraneous or subsequent oral conversations or written
                  correspondence between My Wellness ID LLC and User.
                </p>
              </div>
            </div>
            <p>
              The aforementioned terms refer to any and all parties involved in the offer, acceptance, and consideration
              of payment necessary to undertake the process of assisting the Client in the most appropriate manner. The
              most appropriate way to assist Client may include, but is not limited to, formal meetings of a fixed
              duration, correspondence, or any other means that My Wellness ID LLC shall deem necessary for the purpose
              of meeting Client's needs and providing our services/products in accordance with and subject to the laws
              of the State of South Carolina and applicable federal law.
            </p>
            <p>
              <strong>Note:</strong> Any use of the above terminology or other words shall be treated as
              interchangeable, and using the aforementioned terminology in a singular or plural capacity, or any
              variation thereof, shall have the same respective meanings as those generally given to those terms by the
              English language and by the laws enacted by the State of South Carolina.
            </p>
          </div>
        </Grid.Col>
        <Grid.Col span={12} md={12} style={{ paddingLeft: '5%' }}>
          <div>
            <h2>PRIVACY STATEMENT</h2>
            <p>
              My Wellness ID LLC is committed to protecting your privacy. My Wellness ID LLC does not disseminate
              customer information to third parties for any reason whatsoever. The only people who have access to
              information given to us by Users are authorized employees and representatives, who use said information
              solely for purposes related to My Wellness ID LLC services and product offerings. We constantly review our
              systems and data to ensure the best possible service to our customers.
            </p>
          </div>
        </Grid.Col>
        <Grid.Col span={12} md={12} style={{ paddingLeft: '5%' }}>
          <div>
            <h2>CONFIDENTIALITY </h2>
            <p>
              My Wellness ID LLC will not sell, share, or rent your personal information to any third party or use any
              address (electronic or physical) for unsolicited purposes. Any e-mails sent by My Wellness ID LLC will
              only be used in connect with the provision of services and products.
            </p>
          </div>
        </Grid.Col>
        <Grid.Col span={12} md={12} style={{ paddingLeft: '5%' }}>
          <div>
            <h2>CONDUCT</h2>
            <p>
              User is responsible for its own conduct and activities on, through, or related to My Wellness ID LLC in
              any way whatsoever. By accessing, using, or creating an account on MYWELLNESSID.COM, you accept sole
              responsibility for all conduct or activities on, through, or by use of your account and/or access.
            </p>
          </div>
        </Grid.Col>
        <Grid.Col span={12} md={12} style={{ paddingLeft: '5%' }}>
          <div>
            <h2>DISCLAIMER</h2>
            <p>
              My Wellness ID LLC does not control nor is responsible for any User and their conduct. My Wellness ID LLC
              makes no representations or warranties with respect to said Users and their conduct.
            </p>
            <p>
              Users are solely responsible for their interaction with or reliance on any User or User conduct. When
              necessary, appropriate, or prudent, User must perform any and all judicious inquiries, research,
              investigations, and due diligence with regards to Users and their conduct.
            </p>
            <p>
              My Wellness ID LLC is not required to perform any of the aforementioned acts, nor prompt User to undertake
              such actions.
            </p>
          </div>
        </Grid.Col>
        <Grid.Col span={12} md={12} style={{ paddingLeft: '5%' }}>
          <div>
            <h2>EXCLUSIONS AND LIMITATIONS</h2>
            <p>
              My Wellness ID LLC makes no representations or warranties relating to this website and its contents, which
              may be provided by us, any User, affiliate, or third party.
            </p>
            <p>
              My Wellness ID LLC makes no representations or warranties with respect to any inaccuracies or omissions
              that may be found on our website or in our literature, regardless of whether the inaccuracy or omission is
              the result of our conduct or any User conduct.
            </p>
            <p>
              My Wellness ID LLC is excluded from all liability for damages arising out of or related to any User's
              conduct on or use of this website. This includes, but is not limited to: direct loss, loss of business or
              profits, personal injury, death, damage to your computer, computer software, systems, programs, and the
              data thereon, or any other direct, indirect, consequential, expectation and incidental damages not
              mentioned.
            </p>
          </div>
        </Grid.Col>
        <Grid.Col span={12} md={12} style={{ paddingLeft: '5%' }}>
          <div>
            <h2>TERMINATION OF AGREEMENTS AND REFUNDS POLICY</h2>
            <p>
              My Wellness ID LLC reserves the right to terminate any services agreement or User account, including the
              cancellation of services that are already underway, for any reason whatsoever at our discretion. No
              refunds shall be offered.
            </p>
          </div>
        </Grid.Col>
        <Grid.Col span={12} md={12} style={{ paddingLeft: '5%' }}>
          <div>
            <h2>WAIVER</h2>
            <p>
              No provision of the Terms and Conditions may be waived by a written document (including electronic
              correspondence) or oral agreement between My Wellness ID LLC and the User, unless a proposed waiver is
              expressly written, consented to, and signed by all Parties involved.
            </p>
            <p>
              Failure of any Party to exercise any right or remedy to which they are entitled hereunder shall not
              constitute a waiver thereof and shall not cause a diminution of the obligations under this or any other
              agreement.{' '}
            </p>
          </div>
        </Grid.Col>
        <Grid.Col span={12} md={12} style={{ paddingLeft: '5%' }}>
          <div>
            <h2>INDEMNIFICATION</h2>
            <p>
              My Wellness ID LLC does not control, nor is responsible for any User or User conduct, and makes no
              representations or warranties with respect to Users and their conduct.
            </p>
            <p>
              User hereby agrees to indemnify and hold My Wellness ID LL Cand all company representatives harmless from
              and against any cause of action, third-party claim, demand, or damages arising out of or related to User's
              conduct or activities on, through, or by use of My Wellness ID LLC, and related to or arising out of any
              conduct or activities on, through, or by use of your MYWELLNESSID.COM account.
            </p>
            <p>
              Further, User indemnifies My Wellness ID LLC and all company representatives from payment of any
              attorney's fees and costs incurred by My Wellness ID LLC, company representatives, and User.
            </p>
          </div>
        </Grid.Col>
        <Grid.Col span={12} md={12} style={{ paddingLeft: '5%' }}>
          <div>
            <h2>SEVERABILITY</h2>
            <p>
              If any provision of the Terms and Conditions shall be held to be invalid under any applicable laws, the
              invalidity shall not affect any other provision of this agreement that can be given effect without the
              invalid provision, and, to this end, the provisions of the agreement are severable.
            </p>
          </div>
        </Grid.Col>
        <Grid.Col span={12} md={12} style={{ paddingLeft: '5%' }}>
          <div>
            <h2>LOG FILES</h2>
            <p>
              MYWELLNESSID.COM uses IP addresses to analyze trends, administer our website, track User movement, and
              gather broad demographic information for aggregate use. IP addresses are not linked to personally
              identifiable information, and, as previously mentioned, My Wellness ID LLC will not disseminate any
              personal information to third parties for any purpose.
            </p>
            <p>
              Our web servers automatically log standard access information (including, but not limited to browser type,
              access times/open mail, read receipts, URL requested, and referral URLs) for purposes of systems
              administration, detecting usage patterns, and troubleshooting. This information is not shared with third
              parties and is used only within the Company by its employees, representatives, and affiliates. Any
              personally identifiable information related to this data will never be used for anything other than the
              aforementioned purposes unless the Company receives User's explicit permission.
            </p>
          </div>
        </Grid.Col>
        <Grid.Col span={12} md={12} style={{ paddingLeft: '5%' }}>
          <div>
            <h2>COOKIES</h2>
            <p>
              Like most interactive websites, MYWELLNESSID.COM uses cookies to enable us to retrieve details about User
              for each visit. Cookies are used in some areas of our website to enable the functionality of the site and
              make the site easier to use for those accessing or using MYWELLNESSID.COM site and/or services.
            </p>
            <p>
              Some of our affiliate partners may also use cookies, and My Wellness ID LLC makes no representations or
              warranties as to the use of cookies by other affiliates or third parties and the policies or conditions
              surrounding others' use of cookies.
            </p>
          </div>
        </Grid.Col>
        <Grid.Col span={12} md={12} style={{ paddingLeft: '5%' }}>
          <div>
            <h2>LINKS TO THE WEBSITE</h2>
            <p>
              You may not create a link to any page of this website without My Wellness ID LLC prior written consent. In
              the event that User creates a link to a page of this website, User does so at their own risk and the
              exclusions, limitations, warranties, and representations within the Terms and Conditions govern your use
              of this website by linking to it.
            </p>
          </div>
        </Grid.Col>
        <Grid.Col span={12} md={12} style={{ paddingLeft: '5%' }}>
          <div>
            <h2>LINKS FROM THIS WEBSITE</h2>
            <p>
              My Wellness ID LLC does not monitor or review the content of third parties, affiliates, or Users' websites
              that are linked to My Wellness ID LLC website. Opinions expressed or material appearing on such websites
              are not shared nor endorsed by us, and My Wellness ID LLC shall not be regarded as a publisher or endorser
              of any such opinions or content.
            </p>
            <p>
              My Wellness ID LLC is not responsible for the privacy practices or content associated with or found on
              said websites. User is responsible for being aware of the privacy practices, terms and conditions, and
              content of these sites, and also for evaluating the security and trustworthiness of any other site
              connected to or accessed through MYWELLNESSID.COM
            </p>
            <p>
              My Wellness ID LLC is not responsible for any loss, harm, or damage whatsoever resulting from User's
              interaction with other websites linked from MYWELLNESSID.COM
            </p>
          </div>
        </Grid.Col>
        <Grid.Col span={12} md={12} style={{ paddingLeft: '5%' }}>
          <div>
            <h2>BINDING ARBITRATION, WAIVER OF JURY TRIAL</h2>
            <p>
              UNLESS EXPRESSLY PROHIBITED BY APPLICABLE LAW, THE USER HEREBY WAIVES THE RIGHT TO TRIAL BY JURY OF ANY
              MATTERS OR CLAIMS ARISING OUT OF THESE TERMS AND CONDITIONS OR ANY OTHER AGREEMENT EXECUTED IN CONNECTION
              HEREWITH OR OUT OF THE CONDUCT OF THE RELATIONSHIP BETWEEN THE USER AND MY WELLNESS ID LLC. FURTHER, THE
              USER HEREBY CERTIFIES THAT NO REPRESENTATIVE OR AGENT OF MY WELLNESS ID LLC, NOR MY WELLNESS ID LLC
              COUNSEL, HAS REPRESENTED, EXPRESSLY OR OTHERWISE, THAT MY WELLNESS ID LLC WOULD NOT SEEK TO ENFORCE THIS
              WAIVER OR RIGHT TO JURY TRIAL PROVISION. NO REPRESENTATIVE OR AGENT OF MY WELLNESS ID LLC, NOR MY WELLNESS
              ID LLC COUNSEL, HAS THE AUTHORITY TO WAIVE, CONDITION OR MODIFY THIS PROVISION.
            </p>
          </div>
        </Grid.Col>
        <Grid.Col span={12} md={12} style={{ paddingLeft: '5%' }}>
          <div>
            <h2>NOTIFICATION OF CHANGES</h2>
            <p>
              My Wellness ID LLC reserves the right to change the Terms and Conditions at its sole discretion. User's
              continued use of the website signifies continued acceptance of any adjustment to the Terms and Conditions.
              If My Wellness ID LLC chooses to change its privacy policy, those changes will be announced and sent to
              all Users. Any changes to our privacy policy will be posted on the Company's website, at which point they
              become effective and applicable.
            </p>
          </div>
        </Grid.Col>
        <Grid.Col span={12} md={12} style={{ paddingLeft: '5%' }}>
          <div>
            <p>© My Wellness ID LLC 2024 All Rights Reserved</p>
          </div>
        </Grid.Col>
      </Grid>
    </Box>
  );
}

export default TermsAndConditions;
