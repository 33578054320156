import React, { useEffect, useState } from 'react';
import { Modal, Text, Box, Card, Center, createStyles, ScrollArea, Badge, Grid } from '@mantine/core';
import { getAssessmentList } from '../../fhirApi';
import { useMedplum } from '@medplum/react';

interface AssessmentDetailsProps {
    isOpen: boolean;
    handleClose: () => void;
    assessmentDetails: any;
}

const useStyles = createStyles((theme) => ({
    card: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
      height: '100%',
      borderRadius: '6px',
      '&:hover': {
        backgroundColor: '#F8FBFF',
      } 
    },
  
    section: {
      padding: '0.7rem 1.25rem !important',
      borderBottom: '0px !important',
    },
  
    header: {
      padding: theme.spacing.md,
      borderBottom: `1px solid #EEF1F6 !important`,
    },

    dBlock: {
      [theme.fn.smallerThan('sm')]: {
        display: 'block',
      },
      justifyContent: 'space-between',
    },
  }));

const AssessmentDetailsModel: React.FC<AssessmentDetailsProps> = (props : AssessmentDetailsProps) => {
  const { classes } = useStyles();
  const medplum = useMedplum();
  const [assessmentData, setAssessmentData] = useState([]); 
  const { assessmentDetails } = props;

  useEffect(() => {
    const fetchAssessmentList = async () => {
      try {
        const response = await getAssessmentList(medplum, assessmentDetails.id);
        const filteredData = response?.data.entry.filter((entry: { resource: { id: any; }; }) => entry.resource.id === assessmentDetails.id);
        const assessmentItem = filteredData[0]?.resource.item;
        setAssessmentData(assessmentItem);
      } catch (error) {
        console.error('Failed to fetch assessment list:', error);
      }
    };

    fetchAssessmentList();
  }, [assessmentDetails.id]);

  return (
    <>
      <Modal.Root
        opened={props.isOpen}
        onClose={props.handleClose}
        size="xl"
        scrollAreaComponent={ScrollArea.Autosize}
        className='assessment-model'
      >
        <Modal.Overlay />
        <Modal.Content px="lg" py="sm">
          <Modal.Header sx={{ padding: '1rem 1.50rem' }}>
            <Modal.Title>{props.assessmentDetails.assessmentName}</Modal.Title>
            <Grid sx={{ gap: '15px' }}>
              <Badge sx={{ background: '#E9F3FF', color: '#000', padding: '12px', fontSize: '12px', fontWeight: 500 }}>{props.assessmentDetails.completionDate}</Badge>
              <Badge sx={{ background: '#58CE96', color: '#000', padding: '12px', fontSize: '12px', textTransform: 'capitalize', fontWeight: 500 }}>{props.assessmentDetails.status}</Badge>
              <Modal.CloseButton size={25} />
            </Grid>
          </Modal.Header>
          <Modal.Body sx={{ marginBottom: '20px' }}>
            <Box px="lg" py="sm" sx={{ padding: '0 !important' }}>
              {(assessmentData as any[])?.map((question: any, questionIndex: number) => {
                const hasDiseaseName = question.text === 'Disease Name';

                if (hasDiseaseName) {
                  return null;
                }

                return (
                  <Card withBorder radius="md" className={classes.card} mt={20} key={question.id || questionIndex}>
                    <Card.Section className={classes.header} p="lg" sx={{ padding: '0.70rem 1.25rem !important' }}>
                      <Center className={classes.dBlock}>
                        <Text fw={700} sx={{ color: '#3673B6' }}>Question No. {question.linkId}</Text>
                      </Center>
                    </Card.Section>
                    <Card.Section className={classes.section} p="lg">
                      <Text fw={700}>{question.text}</Text>
                      <Text mt={5}>{question.answer && question.answer[0]?.valueString}</Text>
                    </Card.Section>
                  </Card>
                );
              })}
            </Box>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  )
}

export default AssessmentDetailsModel
