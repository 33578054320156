import { Card, createStyles, Text, Tabs } from '@mantine/core';
import { useEffect, useState } from 'react';
import { assessmentInsightsSummary, progressReportSummary } from '../../fhirApi';
import { useMedplum } from '@medplum/react';
import { useParams } from 'react-router-dom';
import { formatDate } from '@medplum/core';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    height: '100%',
  },

  section: {
    padding: theme.spacing.md,
  },

  footer: {
    padding: theme.spacing.md,
    borderTop: `1px solid #EEF1F6 !important`,
  },

  header: {
    padding: theme.spacing.md,
    borderBottom: `1px solid #EEF1F6 !important`,
  },

  icon: {
    padding: theme.spacing.xs,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#EDF5FF',
    height: '40px',
    width: '40px',
    borderRadius: '25px',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
  },

  scrollbar: {
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: '#ccc #f3f3f3',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#ccc',
        borderRadius: '8px',
        '&:hover': {
          background: '#aaa',
        },
      },
    },
  },

  label: {
    fontWeight: 400,
    fontSize: theme.fontSizes.sm,
    marginRight: theme.spacing.sm,
  },

  highlitedRow: {
    padding: '30px',
    color: 'blue !important',
  },

  dBlock: {
    [theme.fn.smallerThan('sm')]: {
      display: 'block',
    },
  },

  customTabs: {
    '& button': {
      fontSize: '14px',
      color: '#000',
      background: '#eee',
      marginRight: '10px',
    },
    '& button[data-active]': {
      color: '#e9f3ff  ',
      background: '#1b5390 !important',
      backgroundColor: '#1b5390 !important',
    },
  },
}));

export function AIInsights(): JSX.Element {
  const { classes } = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isProgressText, setIsProgressText] = useState(false);
  const [assessmentCompletionDate, setAssessmentCompletionDate] = useState('');
  const medplum = useMedplum();
  const patientID = useParams().id!;

  const AItabs = [
    { value: 'assessmentInsights', name: 'Assessment Insights' },
    // { value: 'progressReport', name: 'Progress Report' },
  ];

  const [activeAITab, setActiveAITab] = useState(AItabs[0].value);
  const [assessmentInsightsSummaryData, setAssessmentInsightsSummaryData] = useState([]);
  const [progressReportSummaryData, setProgressReportSummaryData] = useState([]);
  const [progressReportFetched, setProgressReportFetched] = useState(false);
  const [assessmentInsightsFetched, setAssessmentInsightsFetched] = useState(false);

  useEffect(() => {
    if (!assessmentInsightsFetched) {
      getAssessmentInsightsSummary()
        .then(() => {
          setAssessmentInsightsFetched(true);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [assessmentInsightsFetched]);

  useEffect(() => {
    if (assessmentInsightsFetched && activeAITab === 'assessmentInsights') {
      setIsLoading(false);
    }
  }, [assessmentInsightsFetched, activeAITab]);

  const handleAITabChange = (tab: string) => {
    setActiveAITab(tab);
    setIsLoading(true);

    if (tab === 'assessmentInsights' && !assessmentInsightsFetched) {
      getAssessmentInsightsSummary()
        .then(() => {
          setAssessmentInsightsFetched(true);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else if (tab === 'progressReport' && !progressReportFetched) {
      setIsProgressText(true);
      getProgressReportSummary()
        .then(() => {
          setProgressReportFetched(true);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  const getAssessmentInsightsSummary = async () => {
    try {
      const response = await assessmentInsightsSummary(medplum, patientID);
      setAssessmentCompletionDate(formatDate(response?.data?.authored));
      response?.data.extension.map((data: any) => {
        if (data.url === 'http://ai-insights') {
          const lines = data.valueString.split('\n');
          setAssessmentInsightsSummaryData(lines);
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getProgressReportSummary = async () => {
    setIsProgressText(true);

    try {
      const response = await progressReportSummary(medplum, patientID);
      setProgressReportSummaryData(response?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsProgressText(false);
    }
  };

  return (
    <>
      <Card className={classes.card}>
        <Card.Section className={classes.section} p="lg" style={{ borderBottom: '0px !important' }}>
          <Tabs
            variant="pills"
            radius="lg"
            defaultValue={activeAITab}
            className={classes.customTabs}
            onTabChange={(newTab: string) => handleAITabChange(newTab)}
          >
            <Tabs.List style={{ whiteSpace: 'nowrap', flexWrap: 'nowrap' }}>
              {AItabs.map((t) => (
                <Tabs.Tab key={t.value} value={t.value}>
                  {t.name}
                </Tabs.Tab>
              ))}
            </Tabs.List>

            {AItabs.map((t) => (
              <Tabs.Panel key={t.value} value={t.value} className="customAITabPanel">
                {isLoading ? (
                  <>
                    <span className="loading">
                      {isProgressText && <Text fw={600}>Generating progress report</Text>}
                      <span className="loading__dot"></span>
                      <span className="loading__dot"></span>
                      <span className="loading__dot"></span>
                    </span>
                  </>
                ) : (
                  <>
                      {t.value === 'assessmentInsights' && (
                        <>
                          {assessmentInsightsSummaryData.length > 0 && (
                            <Text fw={600} mt={10} sx={{ fontSize: '16px' }}>
                              Insights for Self assessment completed on {assessmentCompletionDate}
                            </Text>
                          )}
                          {assessmentInsightsSummaryData.length === 0 ? (
                            <p>No Data Available.</p>
                          ) : (
                            <ul className="tab-content assessmentInsightsTab">
                              {assessmentInsightsSummaryData.map((line: any, index: number) => (
                                <li key={index} style={{ paddingBottom: '8px' }}>
                                  {line?.replace(/^- /, '')}
                                </li>
                              ))}
                            </ul>
                          )}
                        </>
                      )}
                    {t.value === 'progressReport' && (
                      <>
                        <Text fw={600} mt={10} sx={{ fontSize: '16px' }}>
                          Progress report based on the recent 3 Clinical Notes
                        </Text>
                        <div
                          className="tab-content progressReportTab"
                          dangerouslySetInnerHTML={{ __html: progressReportSummaryData }}
                        />
                      </>
                    )}
                  </>
                )}
              </Tabs.Panel>
            ))}
          </Tabs>
        </Card.Section>
      </Card>
    </>
  );
}
