/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Box, Card, Grid, Title, RingProgress } from '@mantine/core';
import React from 'react';
import { IconArrowRight } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const FeaturedCards = (data?: any) => {
  return (
    <Box px="xs" py="sm">
      <Grid>
        {data.cardsData.map((card: any, index: number) => (
          <Grid.Col key={index} span={12} sm={6} lg={3}>
            <Card shadow="xs" radius="md" className={card.cardTheme}>
              <Card.Section
                display="flex"
                p="md"
                sx={{
                  justifyContent: 'space-between',
                  borderBottom: '1px solid #e2e2e2',
                  alignItems: 'center',
                }}
              >
                <IconAndText ImageSrc={card.ImageSrc} text={card.text} />
                {card.isCheckCard && (
                  <Link to={card.routeTo} style={{ color: '#d3d3d3' }}>
                    <IconArrowRight />
                  </Link>
                )}
              </Card.Section>
              <Card.Section display="flex" p="md" sx={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
                <HeadingAndDesc text={card.counts} subText={card.subText}></HeadingAndDesc>
                <RingProgress
                  size={70}
                  thickness={9}
                  rootColor={card.progressBg}
                  sections={[{ value: 100, color: card.progressHighlight }]}
                />
              </Card.Section>
            </Card>
          </Grid.Col>
        ))}
      </Grid>
    </Box>
  );
};

export const StyledTypographyDetail = styled(Title)({
  fontSize: '14px',
  fw: '500',
});

export const IconAndText: React.FC<{
  ImageSrc: any;
  text?: React.ReactNode;
}> = ({ ImageSrc, text }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <img src={ImageSrc} className='dashboard-img'/>
    <StyledTypographyDetail
      sx={{
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}
      ml="xs"
      fw="600"
      variant="lg"
    >
      {text}
    </StyledTypographyDetail>
  </div>
);

export const StyledTypographyMain = styled(Title)({
  fontSize: '28px',
  lineHeight: '32px',
  letterSpacing: '0.5px',
  color: '#0A1E28',
});

export const StyledTypographyText = styled(Title)({
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '0.5px',
  color: '#0A1E28',
});

export const HeadingAndDesc: React.FC<{
  text?: React.ReactNode;
  subText?: React.ReactNode;
}> = ({ text, subText }) => (
  <div
    style={{
      display: 'block',
    }}
  >
    <StyledTypographyMain
      sx={{
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}
      fw="bold"
      variant="lg"
    >
      {text}
    </StyledTypographyMain>
    <StyledTypographyText>{subText}</StyledTypographyText>
  </div>
);

export default FeaturedCards;
